// Styles for MobileExplanation
import styled from 'styled-components';
import { media } from '../../styles/breakpoint';

export const MobileExplanation = styled.div`
  background: #bfd1ffd4;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  height: fit-content;
`;

export const Notification = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  flex-basis: 0;
  flex-grow: 1;
`;

export const Icon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-size: 20px;
  background: #ffff00;
  border-radius: 100%;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);

  @media ${media.tablet} {
  }

  @media ${media.mobile} {
    font-size: 12px;
  }
`;

export const Description = styled.p<{ weight?: number }>`
  font-size: 16px;
  color: #19274c;
  margin: 0;
  font-weight: ${({ weight }) => weight || 500};

  @media ${media.tablet} {
  }

  @media ${media.mobile} {
    font-size: 14px;
  }
`;

export const Button = styled.div`
  background: #c2fefe;
  text-align: center;
  padding: 16px;
  margin: 16px 0 24px 0;

  @media ${media.tablet} {
  }

  @media ${media.mobile} {
    padding: 12px;
    margin: 8px 0 16px 0;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;

  @media ${media.tablet} {
  }

  @media ${media.mobile} {
    padding: 12px;
  }
`;

export const PlayButton = styled.div`
  padding: 16px;
  background: #c2fefe;
  text-align: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
`;

export const Skeleton = styled.div<{ width?: string }>`
  background: #cccccc;
  width: ${({ width }) => width || `170px`};
  height: 22px;
  margin-bottom: 8px;
`;

export const ListGames = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
`;

export const Container = styled.div`
  .patent {
    font-size: 12px;
  }
`;
